import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  FetchProductsFilters,
  ProductsFiltersNavBasePath,
  ProductsAiSearchEnabled,
  ProductsCustomExitNavPath,
  ProductsI18nCustomBaseTitle,
  ChangeProductsFiltersFunc,
  FetchProductsTotalCount,
  FetchProductsSort,
  FetchProductsCacheKey
} from '../../../productsTypes';
import { FetchItemsSort } from '../../../../../types';

import { ProductsIndexPageSubHeaderImageScaleButton } from './components/ProductsIndexPageSubHeaderImageScaleButton';
import { ProductsIndexPageSubHeaderToggleViewButton } from './components/ProductsIndexPageSubHeaderToggleViewButton';

import { ProductsFavoriteButtonFilter } from '../../filters/ProductsFavoriteButtonFilter';
import { ProductsSortDropdownButton } from '../../buttons/ProductsSortDropdownButton';
import { ProductsAiSearchNotAvailableWrapper } from '../../../helpers/ProductsAiSearchNotAvailableWrapper';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { ProductsIndexPageBreadcrumbs } from '../ProductsIndexPageBreadcrumbs';
import { ProductsPermissions } from '../../../productsConstants';

interface ProductsIndexPageSubHeaderProps {
  aiSearchEnabled?: ProductsAiSearchEnabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
  currentSort: FetchItemsSort;
  customExitNavPath?: ProductsCustomExitNavPath;
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  i18nCustomBaseTitle?: ProductsI18nCustomBaseTitle;
  isGridView: boolean;
  productsBaseCacheKey: FetchProductsCacheKey;
  productsFilters: FetchProductsFilters;
  productsTotalCount: FetchProductsTotalCount;
  scale: number;
  setScale: (scale: number) => void;
  sortProducts: (nextSort: FetchProductsSort) => void;
  toggleView: () => void;
  withoutStockLink?: boolean;
}

function ProductsIndexPageSubHeader({
  aiSearchEnabled,
  changeProductsFilters,
  currentSort,
  customExitNavPath,
  filtersNavBasePath,
  i18nCustomBaseTitle,
  isGridView,
  productsBaseCacheKey,
  productsFilters,
  productsTotalCount,
  scale,
  setScale,
  sortProducts,
  toggleView,
  withoutStockLink
}: ProductsIndexPageSubHeaderProps) {
  return (
    <div className="flex items-center sm:items-start lg:py-2 flex-col sm:flex-row px-4">
      <ProductsAiSearchNotAvailableWrapper
        aiSearchEnabled={aiSearchEnabled}
        className="flex items-center h-10 w-full"
      >
        <ProductsIndexPageBreadcrumbs
          changeProductsFilters={changeProductsFilters}
          customExitNavPath={customExitNavPath}
          disabled={aiSearchEnabled}
          filtersNavBasePath={filtersNavBasePath}
          i18nCustomBaseTitle={i18nCustomBaseTitle}
          productsFilters={productsFilters}
          productsTotalCount={productsTotalCount}
          withoutStockLink={withoutStockLink}
        />
      </ProductsAiSearchNotAvailableWrapper>

      <ProductsAiSearchNotAvailableWrapper
        aiSearchEnabled={aiSearchEnabled}
        className="flex justify-end mt-4 sm:mt-0 space-x-1"
      >
        <ProductsSortDropdownButton
          currentSort={currentSort}
          disabled={aiSearchEnabled}
          productsBaseCacheKey={productsBaseCacheKey}
          sortProducts={sortProducts}
          withFeatured={!isEmpty(productsFilters.nameSkuTags)}
        />

        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCTS_FAVORITE_BUTTON_FILTER}
        >
          <ProductsFavoriteButtonFilter
            productsFilters={productsFilters}
            disabled={aiSearchEnabled}
            changeProductsFilters={changeProductsFilters}
          />
        </CheckPermissions>

        {isGridView && (
          <ProductsIndexPageSubHeaderImageScaleButton
            scale={scale}
            setScale={setScale}
          />
        )}

        <ProductsIndexPageSubHeaderToggleViewButton
          isGridView={isGridView}
          toggleView={toggleView}
        />
      </ProductsAiSearchNotAvailableWrapper>
    </div>
  );
}

export default memo<ProductsIndexPageSubHeaderProps>(
  ProductsIndexPageSubHeader
);
