import React, { useMemo } from 'react';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ProductsFiltersNavBasePath,
  ProductsCustomExitNavPath,
  ProductsI18nCustomBaseTitle,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { FetchProductCategoriesQueryResponse } from '../../../queries/fetchProductCategories.query';

import { useFinProductCategories } from '../../../hooks/useFinProductCategories';

import { ProductsIndexPageCategoriesNavBody } from '../ProductsIndexPageCategoriesNav/components/ProductsIndexPageCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { getProductClientIdsCacheKeyPart } from '../../../utils/getProductClientIdsCacheKeyPart';

import { ProductCache } from '../../../ProductCache';

interface ProductsLibraryPageCategoriesNavProps {
  clientIds: ProductClientID[];
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  customExitNavPath?: ProductsCustomExitNavPath;
  i18nCustomBaseTitle?: ProductsI18nCustomBaseTitle;
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function ProductsLibraryPageCategoriesNav({
  clientIds,
  filtersNavBasePath,
  customExitNavPath,
  i18nCustomBaseTitle,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsLibraryPageCategoriesNavProps) {
  const {
    productCategories,
    productCategoriesErrorMessage,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useFinProductCategories({
    cacheKey: ProductCache.categoriesLibraryCacheKey(
      getProductClientIdsCacheKeyPart({ clientIds })
    ),
    facilityGroupsByProduct: {
      clientIds
    }
  });

  const productCategoriesWithParents = useMemo<
    FetchProductCategoriesQueryResponse[]
  >(() => {
    const parentCategories = compact(
      productCategories.map((category) => category.parent)
    );

    return sortBy(
      uniqBy([...productCategories, ...parentCategories], 'id'),
      'name'
    );
  }, [productCategories]);

  return (
    <div className="p-4">
      <AlertMessage message={productCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={productCategoriesIsPlaceholderData || productCategoriesFetched}
      >
        <ProductsIndexPageCategoriesNavBody
          productCategories={productCategoriesWithParents}
          filtersNavBasePath={filtersNavBasePath}
          customExitNavPath={customExitNavPath}
          i18nCustomBaseTitle={i18nCustomBaseTitle}
          productsFilters={productsFilters}
          disabled={disabled}
          changeProductsFilters={changeProductsFilters}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ProductsLibraryPageCategoriesNav;
