import { useEffect } from 'react';
import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import {
  FetchProductsFilters,
  ChangeProductsFiltersFunc,
  ProductCategoryID
} from '../../../../../productsTypes';

interface ProductsIndexContentCategoriesFiltersOptions {
  productsFilters: FetchProductsFilters;
  childCategoryIds?: ProductCategoryID[];
  productParentCategoryIds?: ProductCategoryID[];
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function useProductsIndexContentCategoriesFilters({
  productsFilters,
  childCategoryIds = null,
  productParentCategoryIds = null,
  changeProductsFilters
}: ProductsIndexContentCategoriesFiltersOptions) {
  const currentFiltersChildCategoryId =
    productsFilters?.productCategoryId?.in || null;
  const currentFiltersParentCategoryIds =
    productsFilters?.productParentCategoryIds || null;

  useEffect(() => {
    if (
      !isEqual(childCategoryIds, currentFiltersChildCategoryId) ||
      !isEqual(productParentCategoryIds, currentFiltersParentCategoryIds)
    ) {
      const changeFilters = {
        productCategoryId: { in: childCategoryIds },
        productParentCategoryIds
      };

      const resetFilters = compact([
        !isEmpty(childCategoryIds) ? null : 'productCategoryId',
        !isEmpty(productParentCategoryIds) ? null : 'productParentCategoryIds'
      ]);

      changeProductsFilters(changeFilters, resetFilters);
    }
  }, [
    changeProductsFilters,
    childCategoryIds,
    currentFiltersChildCategoryId,
    currentFiltersParentCategoryIds,
    productParentCategoryIds
  ]);
}

export default useProductsIndexContentCategoriesFilters;
