import { useCallback, useMemo, useEffect } from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';

import { useFinProductTypes } from '../../../../../hooks/useFinProductTypes';
import { usePreviousValue } from '../../../../../../../common/hooks/usePreviousValue';

import { ProductCache } from '../../../../../ProductCache';

import {
  CategoryID,
  FetchCategoriesScopes,
  FetchCategoriesSortTypes,
  FetchFinCategoriesFiltersProductFilters
} from '../../../../../../categories/categoriesTypes';

import {
  FetchProductsFilters,
  FetchFinProductCategoriesFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../../../productsTypes';

import { getProductClientIdsCacheKeyPart } from '../../../../../utils/getProductClientIdsCacheKeyPart';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.PRODUCTS_TYPE }
};

const initialSort = [FetchCategoriesSortTypes.ID_ASC];

interface ProductTypesDataItem {
  id: string;
  label: string;
}

interface useProductsTypesFilterProps {
  clientIds?: ProductClientID[];
  selectedIds: CategoryID[];
  name: string;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function useProductsTypesFilter({
  clientIds,
  selectedIds = [],
  name,
  productsFilters,
  changeProductsFilters
}: useProductsTypesFilterProps) {
  const categoryIds = productsFilters?.productCategoryId?.in;
  const parentCategoryIds = productsFilters?.productParentCategoryIds;
  const materialIds = productsFilters?.materialIds;
  const colorIds = productsFilters?.colorIds;

  const productTypeId = productsFilters?.productTypeId;
  const seasonalId = productsFilters?.seasonalId;
  const styleId = productsFilters?.styleId;
  const brandId = productsFilters?.brandId;

  const cacheKey = isEmpty(clientIds)
    ? ProductCache.typesFilterCacheKey()
    : ProductCache.typesLibraryFilterCacheKey(
        getProductClientIdsCacheKeyPart({ clientIds })
      );

  const typesByProduct: FetchFinCategoriesFiltersProductFilters = merge(
    {
      nda: productsFilters?.nda,
      favorite: productsFilters?.favorite,
      blocked: productsFilters?.blocked,
      styleId,
      brandId,
      seasonalId,
      productTypeId
    },
    isEmpty(clientIds) ? null : { productClientId: { in: clientIds } },
    isEmpty(categoryIds) ? null : { productCategoryId: { in: categoryIds } },
    isEmpty(parentCategoryIds)
      ? null
      : {
          productParentCategoryId: { in: parentCategoryIds }
        },
    isEmpty(materialIds)
      ? null
      : {
          materialId: { in: materialIds }
        },
    isEmpty(colorIds)
      ? null
      : {
          colorId: { in: colorIds }
        }
  );

  const previousTypesByProduct = usePreviousValue(typesByProduct);

  const defaultFilters: FetchFinProductCategoriesFilters = merge(
    {},
    defaultInitialFilters,
    { typesByProduct }
  );

  const {
    productTypes,
    productTypesErrorMessage,
    productTypesFetched,
    productTypesIsPlaceholderData,
    productTypesFetchingNextPage,
    hasNextProductTypesPage,
    productTypesFilterSearchValue,
    loadMoreProductTypes,
    filterProductTypes,
    changeProductTypesFilters
  } = useFinProductTypes({
    cacheKey,
    initialFilters: defaultFilters,
    initialSort
  });

  useEffect(() => {
    if (!isEqual(typesByProduct, previousTypesByProduct)) {
      filterProductTypes(defaultFilters);
    }
  }, [
    defaultFilters,
    filterProductTypes,
    previousTypesByProduct,
    typesByProduct
  ]);

  const {
    productTypes: productTypesSelected,
    productTypesFilters: productTypesSelectedFilters,
    changeProductTypesFilters: changeProductTypesFiltersSelected,
    productTypesErrorMessage: productsTypesSelectedErrorMessage
  } = useFinProductTypes({
    cacheKey: ProductCache.typesSelectedFilterCacheKey(),
    initialFilters: {
      ...defaultInitialFilters,
      id: { in: selectedIds }
    },
    initialSort,
    options: {
      enabled: !isEmpty(selectedIds),
      enabledPlaceholder: !isEmpty(selectedIds)
    }
  });

  useEffect(() => {
    if (isEmpty(selectedIds) && isEmpty(productTypesSelectedFilters?.id?.in)) {
      return;
    }

    if (!isEqual(selectedIds, productTypesSelectedFilters?.id?.in)) {
      changeProductTypesFiltersSelected({
        id: { in: selectedIds }
      });
    }
  }, [
    changeProductTypesFiltersSelected,
    productTypesSelectedFilters?.id?.in,
    selectedIds
  ]);

  const handleProductTypesFilterSearch = useCallback(
    (input: string) => {
      changeProductTypesFilters(
        { name: { ilike: input } },
        input ? [] : ['name']
      );
    },
    [changeProductTypesFilters]
  );

  const productTypesFilterSelectedData = useMemo(() => {
    const selectedFetchedData = productTypesSelected.map((category) => ({
      id: category.id as string,
      label: category.name
    }));

    const selectedData = compact(
      selectedIds?.map((id) => find(selectedFetchedData, { id }))
    );
    return selectedData;
  }, [productTypesSelected, selectedIds]);

  const productsTypesFilterData = useMemo<ProductTypesDataItem[]>(() => {
    return sortBy(
      productTypes?.map((category) => ({
        id: category.id as string,
        label: category.name
      })),
      'label'
    );
  }, [productTypes]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeProductsFilters(
        { [name]: { in: changedFilters?.[name] || [] } },
        removeFilters
      );
    },
    [changeProductsFilters, name]
  );

  return {
    allProductsTypesFilterData: uniqBy(
      compact(concat(productTypesFilterSelectedData, productsTypesFilterData)),
      'id'
    ),
    productTypesFilterSelectedData,
    productsTypesFilterData,
    productTypesFilterErrorMessage: productTypesErrorMessage,
    productsTypesFilterSelectedErrorMessage: productsTypesSelectedErrorMessage,
    productTypesFilterFetched: productTypesFetched,
    productTypesFilterIsPlaceholderData: productTypesIsPlaceholderData,
    productTypesFilterFetchingNextPage: productTypesFetchingNextPage,
    hasNextProductTypesFilterPage: hasNextProductTypesPage,
    productTypesFilterSearchValue,
    handleProductTypesFilterSearch,
    loadMoreProductTypesFilter: loadMoreProductTypes,
    handleChangeProductsTypesFilter: handleChange
  };
}

export default useProductsTypesFilter;
