import React, { useMemo, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { useProductStyles } from '../../../hooks/useProductStyles';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckboxGroupFilter } from '../../../../../helpers/filters/CheckboxGroupFilter';
import { Translate } from '../../../../../helpers/Translate';

import { getProductClientIdsCacheKeyPart } from '../../../utils/getProductClientIdsCacheKeyPart';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductsStylesFilterDataItem } from './ProductsStylesFilter.types';

import { ProductCache } from '../../../ProductCache';

interface ProductsStylesFilterProps {
  clientIds?: ProductClientID[];
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'styleId' as const;

function ProductsStylesFilter({
  clientIds,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsStylesFilterProps) {
  const cacheKey = isEmpty(clientIds)
    ? ProductCache.stylesFilterCacheKey()
    : ProductCache.stylesLibraryFilterCacheKey(
        getProductClientIdsCacheKeyPart({ clientIds })
      );
  const addInitialFilters = isEmpty(clientIds)
    ? undefined
    : { productClientIds: clientIds };

  const {
    productStyles,
    productStylesErrorMessage,
    productStylesFetched,
    productStylesIsPlaceholderData
  } = useProductStyles({
    cacheKey,
    addInitialFilters
  });

  const productsStylesFilterData = useMemo<
    ProductsStylesFilterDataItem[]
  >(() => {
    return productStyles.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [productStyles]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeProductsFilters?.(
        { [name]: { in: changedFilters?.[name] || [] } },
        removeFilters
      );
    },
    [changeProductsFilters]
  );

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.styles.singular} />
        </h6>
      </div>

      <AlertMessage message={productStylesErrorMessage} />

      <LoadingSkeleton
        loaded={productStylesIsPlaceholderData || productStylesFetched}
      >
        <CheckboxGroupFilter
          dataGa="products-styles-filter"
          disabled={disabled}
          inputWrapperClassName="flex flex-col sm:flex-row sm:flex-wrap -mb-2"
          items={productsStylesFilterData}
          name={name}
          onChange={handleChange}
          value={productsFilters?.[name]?.in as string[]}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ProductsStylesFilter;
