import React, { ReactNode } from 'react';

import { I18nText } from '../../../../../../../types';

import { IndexLayout } from '../../../../../../common/layouts/IndexLayout';
import { MainScrollWrapper } from '../../../../../../common/helpers/MainScrollWrapper';

interface ProductsIndexContentLayoutProps {
  action: string;
  children: ReactNode;
  customSecondaryMenu?: ReactNode;
  header?: ReactNode | null;
  modalHeader?: ReactNode | null;
  i18nTitle?: I18nText;
  sidebar?: ReactNode;
  subHeader?: ReactNode;
  withScrollRestore?: boolean;
  withScrollToTop?: boolean;
  withoutActionError?: boolean;
  isModal?: boolean;
}

function ProductsIndexContentLayout({
  action,
  children,
  customSecondaryMenu,
  header,
  modalHeader,
  i18nTitle,
  sidebar,
  subHeader,
  withScrollRestore,
  withScrollToTop,
  withoutActionError,
  isModal
}: ProductsIndexContentLayoutProps) {
  return isModal ? (
    <div className="flex-1 flex">
      {customSecondaryMenu}
      <MainScrollWrapper>
        <div className="flex-1 z-0">
          <div className="pt-6 pb-8">
            <div className="flex flex-col space-y-8">
              {modalHeader}
              {subHeader}
              {children}
            </div>
          </div>
        </div>
      </MainScrollWrapper>

      {sidebar}
    </div>
  ) : (
    <IndexLayout
      action={action}
      i18nTitle={i18nTitle}
      withScrollRestore={withScrollRestore}
      withScrollToTop={withScrollToTop}
      withoutActionError={withoutActionError}
      header={header}
      sidebar={sidebar}
      customSecondaryMenu={customSecondaryMenu}
      subHeader={subHeader}
    >
      {children}
    </IndexLayout>
  );
}

export default ProductsIndexContentLayout;
