import React from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  ChangeProductsFiltersFunc,
  FetchProductsFilters,
  ProductClientID,
  ProductsAiClipSearchEnabled,
  ProductsAiSearchEnabled,
  ProductsAiTextSearchEnabled,
  ProductsCustomExitNavPath,
  ProductsFiltersNavBasePath,
  ProductsI18nCustomBaseTitle
} from '../../../productsTypes';
import { TeamNanoID } from '../../../../teams/teamsTypes';

import { ProductsIndexFilters } from '../../filters/ProductsIndexFilters';
import { ProductsIndexPageCategoriesNav } from '../ProductsIndexPageCategoriesNav';
import { ProductsLibraryPageCategoriesNav } from '../ProductsLibraryPageCategoriesNav';
import { ProductsAiSearchNotAvailableWrapper } from '../../../helpers/ProductsAiSearchNotAvailableWrapper';
import { ProductsIndexPageMenuWrapper } from './components/ProductsIndexPageMenuWrapper';

interface ProductsIndexPageMenuProps {
  aiClipSearchEnabled?: ProductsAiClipSearchEnabled;
  aiSearchEnabled?: ProductsAiSearchEnabled;
  aiTextSearchEnabled?: ProductsAiTextSearchEnabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
  clientIds?: ProductClientID[];
  companyNanoId?: TeamNanoID;
  customExitNavPath?: ProductsCustomExitNavPath;
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  i18nCustomBaseTitle?: ProductsI18nCustomBaseTitle;
  productsFilters: FetchProductsFilters;
  withoutMyCompanyFavorite?: boolean;
  isModal?: boolean;
}

function ProductsIndexPageMenu({
  aiClipSearchEnabled,
  aiSearchEnabled,
  aiTextSearchEnabled,
  changeProductsFilters,
  clientIds,
  companyNanoId,
  customExitNavPath,
  filtersNavBasePath,
  i18nCustomBaseTitle,
  productsFilters,
  withoutMyCompanyFavorite,
  isModal
}: ProductsIndexPageMenuProps) {
  return (
    <ProductsIndexPageMenuWrapper
      companyNanoId={companyNanoId}
      isModal={isModal}
    >
      <ProductsAiSearchNotAvailableWrapper aiSearchEnabled={aiSearchEnabled}>
        {isEmpty(clientIds) ? (
          <ProductsIndexPageCategoriesNav
            filtersNavBasePath={filtersNavBasePath}
            customExitNavPath={customExitNavPath}
            i18nCustomBaseTitle={i18nCustomBaseTitle}
            productsFilters={productsFilters}
            disabled={aiSearchEnabled}
            changeProductsFilters={changeProductsFilters}
          />
        ) : (
          <ProductsLibraryPageCategoriesNav
            clientIds={clientIds}
            filtersNavBasePath={filtersNavBasePath}
            customExitNavPath={customExitNavPath}
            i18nCustomBaseTitle={i18nCustomBaseTitle}
            productsFilters={productsFilters}
            disabled={aiSearchEnabled}
            changeProductsFilters={changeProductsFilters}
          />
        )}
      </ProductsAiSearchNotAvailableWrapper>

      <ProductsIndexFilters
        aiSearchEnabled={aiSearchEnabled}
        aiTextSearchEnabled={aiTextSearchEnabled}
        aiClipSearchEnabled={aiClipSearchEnabled}
        clientIds={clientIds}
        productsFilters={productsFilters}
        changeProductsFilters={changeProductsFilters}
        withoutMyCompanyFavorite={withoutMyCompanyFavorite}
      />
    </ProductsIndexPageMenuWrapper>
  );
}

export default ProductsIndexPageMenu;
