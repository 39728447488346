import React, { useCallback } from 'react';
import cl from 'classnames';
import size from 'lodash/size';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import {
  ChangeProductsFiltersFunc,
  FetchProductsCacheKeys,
  FetchProductsFilterFields,
  FetchProductsFilters,
  FetchProductsSort,
  FetchProductsSortTypes,
  ProductsAiSearchEnabled
} from '../../../productsTypes';

import { IconsEnum } from '../../../../../assets/icons/types';

import { IndexTablePageHeader } from '../../../../common/components/headers/IndexTablePageHeader';
import {
  ProductsAiSearchDetectModalButton,
  ProductsAiSearchOnClipGlobalSearch,
  ProductsAiSearchOnClipSearch,
  ProductsAiSearchOnDetect,
  ProductsAiSearchOnTextSearch,
  ProductsAiSearchTextModalButton
} from '../../../components/containers/ProductsAiSearch';
import { ProductsElasticSearchModalButton } from '../../../components/buttons/ProductsElasticSearchModalButton';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Icon } from '../../../../../helpers/Icon';
import { Translate } from '../../../../../helpers/Translate';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';
import { TextFilter } from '../../../../../helpers/filters/TextFilter';
import { ProductsAiSearchNotAvailableWrapper } from '../../../helpers/ProductsAiSearchNotAvailableWrapper';
import { ProductsAiSearchClipGlobalModalButton } from '../../containers/ProductsAiSearch/components/ProductsAiSearchClipGlobalModalButton';
import { ClearProductsCacheButton } from '../../buttons/ClearProductsCacheButton';

import { aiSearchKeys, productsKeys, words } from '../../../../../locales/keys';

import {
  INITIAL_PRODUCTS_SORT,
  ProductsPermissions
} from '../../../productsConstants';
import { ProductPath } from '../../../ProductPath';

interface ProductsIndexPageHeaderProps {
  aiSearchEnabled?: ProductsAiSearchEnabled;
  allowElasticSearch?: boolean;
  changeProductsFilters: ChangeProductsFiltersFunc;
  onAiClipGlobalSearchSubmit: ProductsAiSearchOnClipGlobalSearch;
  onAiClipSearchSubmit: ProductsAiSearchOnClipSearch;
  onAiDetectSearchSubmit: ProductsAiSearchOnDetect;
  onAiTextSearchSubmit: ProductsAiSearchOnTextSearch;
  productsCacheKeys: FetchProductsCacheKeys;
  productsFilters: FetchProductsFilters;
  withAiClipGlobalSearchFilter?: boolean;
  withAiClipGlobalSearch?: boolean;
  withAiClipSearch?: boolean;
  withAiDetectSearch?: boolean;
  withAiTextSearch?: boolean;
  withoutAddButton?: boolean;
  sortProducts: (nextSort: FetchProductsSort) => void;
}

function ProductsIndexPageHeader({
  aiSearchEnabled,
  allowElasticSearch,
  changeProductsFilters,
  onAiClipGlobalSearchSubmit,
  onAiClipSearchSubmit,
  onAiDetectSearchSubmit,
  onAiTextSearchSubmit,
  productsCacheKeys,
  productsFilters,
  withAiClipGlobalSearchFilter,
  withAiClipGlobalSearch,
  withAiClipSearch,
  withAiDetectSearch,
  withAiTextSearch,
  withoutAddButton,
  sortProducts
}: ProductsIndexPageHeaderProps) {
  const currentUser = useCurrentUser();

  const withElasticSearch =
    allowElasticSearch &&
    currentUser.hasPermissions(
      ProductsPermissions.READ_PRODUCTS_ELASTIC_SEARCH_MODAL_BUTTON
    );

  const buttonsAmount = size(
    compact([
      withAiClipGlobalSearchFilter,
      withAiClipGlobalSearch,
      withAiClipSearch,
      withAiDetectSearch,
      withAiTextSearch,
      withElasticSearch
    ])
  );

  const handleSearch = useCallback<
    (
      changedFilters: { [nameSkuTags: string]: string },
      removeFilters: string[]
    ) => void
  >(
    ({ nameSkuTags }, removeFilters) => {
      changeProductsFilters({ nameSkuTags }, removeFilters);

      const sort = isEmpty(removeFilters)
        ? [FetchProductsSortTypes.SCORE_DESC]
        : INITIAL_PRODUCTS_SORT;

      sortProducts(sort);
    },
    [changeProductsFilters, sortProducts]
  );

  const handleAiClipGlobalSearchFilter =
    useCallback<ProductsAiSearchOnClipGlobalSearch>(
      (data) => {
        changeProductsFilters({
          similarImage: {
            imageUrl: data.fileAttachment.file,
            projectId: null,
            taskId: null
          }
        });
        sortProducts([FetchProductsSortTypes.SCORE_DESC]);
      },
      [changeProductsFilters, sortProducts]
    );

  return (
    <IndexTablePageHeader
      actionButtons={
        <>
          <CheckPermissions
            action={ProductsPermissions.READ_CLEAR_PRODUCTS_CACHE_BUTTON}
          >
            <ClearProductsCacheButton productsCacheKeys={productsCacheKeys} />
          </CheckPermissions>

          {withoutAddButton ? null : (
            <CheckPermissions
              action={ProductsPermissions.READ_NEW_PRODUCT_BUTTON}
            >
              <NextLinkHelper
                className="py-2 pl-2 pr-2 sm:pl-4 sm:pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
                dataGa="products-new-product-link"
                href={ProductPath.create()}
              >
                <Icon
                  className="h-6 w-6 p-0.5 sm:hidden"
                  icon={IconsEnum.PLUS}
                />
                <span className="sr-only sm:not-sr-only">
                  <Translate id={words.add} />
                </span>
              </NextLinkHelper>
            </CheckPermissions>
          )}
        </>
      }
    >
      <div className="flex items-center w-full max-w-lg">
        <div className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full">
          <ProductsAiSearchNotAvailableWrapper
            aiSearchEnabled={aiSearchEnabled}
          >
            <TextFilter
              id="products-text-filter-input"
              dataGa="products-text-filter-input"
              inputClassName={cl(
                'bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 py-2 text-gray-900 w-full',
                {
                  'pr-28': buttonsAmount === 3,
                  'pr-20': buttonsAmount === 2,
                  'pr-12': buttonsAmount !== 2 && buttonsAmount !== 3
                }
              )}
              name={FetchProductsFilterFields.NAME_SKU_TAGS}
              value={productsFilters.nameSkuTags || ''}
              i18nPlaceholder={productsKeys.searchProductsByNameOrSku}
              disabled={aiSearchEnabled}
              onChange={handleSearch}
            />
          </ProductsAiSearchNotAvailableWrapper>

          {withAiClipGlobalSearchFilter ||
          withAiClipGlobalSearch ||
          withAiClipSearch ||
          withAiDetectSearch ||
          withAiTextSearch ||
          withElasticSearch ? (
            <div className="absolute flex inset-y-0 items-center leading-5 pr-1 right-0 text-sm">
              {withAiClipGlobalSearchFilter ? (
                <ProductsAiSearchClipGlobalModalButton
                  className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                  dataGa="products-clip-global-search-filter-button"
                  i18nTitle={aiSearchKeys.imageSearch}
                  icon={IconsEnum.PHOTO_SOLID}
                  iconClassName="h-5 w-5"
                  onSubmit={handleAiClipGlobalSearchFilter}
                />
              ) : null}

              {withAiClipGlobalSearch ? (
                <ProductsAiSearchClipGlobalModalButton
                  className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                  dataGa="products-clip-global-search-button"
                  i18nTitle={aiSearchKeys.imageSearch}
                  icon={IconsEnum.PHOTO_SOLID}
                  iconClassName="h-5 w-5"
                  onSubmit={onAiClipGlobalSearchSubmit}
                  tooltipI18nText={aiSearchKeys.imageSearch}
                />
              ) : null}

              {withAiClipSearch ? (
                <ProductsAiSearchTextModalButton
                  className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                  dataGa="products-clip-search-button"
                  i18nTitle={aiSearchKeys.aiPoweredCLIPSearch}
                  icon={IconsEnum.SEARCH_AI_OUTLINE}
                  iconClassName="h-5 w-5"
                  tooltipI18nText={aiSearchKeys.aiPoweredCLIPSearch}
                  onSubmit={onAiClipSearchSubmit}
                />
              ) : null}

              {withElasticSearch ? (
                <ProductsElasticSearchModalButton
                  className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                  dataGa="products-elastic-search-button"
                  icon={IconsEnum.ELASTIC_SEARCH_SOLID}
                  iconClassName="h-5 w-5"
                  tooltipI18nText={words.elasticSearch}
                />
              ) : null}

              {withAiTextSearch ? (
                <ProductsAiSearchTextModalButton
                  className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                  dataGa="products-ai-text-search-button"
                  i18nTitle={aiSearchKeys.aiPoweredSearch20}
                  icon={IconsEnum.SEARCH_AI_OUTLINE}
                  iconClassName="h-5 w-5"
                  tooltipI18nText={aiSearchKeys.aiPoweredSearch20}
                  onSubmit={onAiTextSearchSubmit}
                />
              ) : null}

              {withAiDetectSearch ? (
                <ProductsAiSearchDetectModalButton
                  className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                  dataGa="products-ai-detect-search-button"
                  icon={IconsEnum.PHOTO_AI_SOLID}
                  iconClassName="h-5 w-5"
                  tooltipI18nText={aiSearchKeys.aiPoweredSearch}
                  onSubmit={onAiDetectSearchSubmit}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </IndexTablePageHeader>
  );
}

export default ProductsIndexPageHeader;
